import React, { useState, useEffect } from 'react';
import './penman.css';



const PenmanHighlight = ({ sntAMRtokens, setSentAMRS, codeOnly, sentID, allClusters, setAllClusters, uploadAMRCorefs, selectedCluster, alignedTokens, setAlignedTokens }) => {
  

  const updateTokenBgc = (tokenDict, amrVar) => {
    if (tokenDict.var === amrVar) {
      console.log(`Updating amrVar ${amrVar} bgc: ${tokenDict.bgc}`);
      const newbgc = tokenDict.bgc === 'white' ? 'primary' : 'white';
      return { ...tokenDict, bgc: newbgc };
    } else {
      return { ...tokenDict };
    }
  };

  const handleTokenClick = (tokenVar, sID) => {
    // update text
    console.log('Updating tokens for sID:', sID);
    console.log('tokenVar to update:', tokenVar);

    // update cluster
    if (selectedCluster > 0) {
      const updatedClusters = JSON.parse(JSON.stringify(allClusters));
      const updatedTargetCluster = updatedClusters[selectedCluster];
      console.log('updatedTargetCluster: ', updatedTargetCluster);
      const tokenIdxs = alignedTokens[sentID]
        .map((tokenDict, index) => (tokenDict.var === tokenVar ? index : -1))
        .filter(index => index !== -1);
      const tokenSurface = tokenIdxs.length === 0 ? '<no surface>' : alignedTokens[sentID][tokenIdxs[0]]['surface'];

      const filteredChips = updatedTargetCluster['nodes'].filter((nodeDict) => nodeDict.var !== tokenVar);
      console.log('filteredChips: ', filteredChips);
      if (filteredChips.length === updatedTargetCluster['nodes'].length) {
        // exist already we remove them
        updatedTargetCluster['nodes'].push({var: tokenVar, surface: tokenSurface});
        const newClusters = {...updatedClusters, [selectedCluster]: updatedTargetCluster};
        setAllClusters(newClusters);
        uploadAMRCorefs(newClusters);
      } else {
        // does not exist yet, we push it to the list
        const newClusters = {...updatedClusters, [selectedCluster]: {id: updatedTargetCluster.id, name: updatedTargetCluster.name, nodes: filteredChips}};
        setAllClusters(newClusters);
        uploadAMRCorefs(newClusters);
      }
    }
    console.log('finished cluster update');
    const newAlignedTokens = alignedTokens.map((perSentTokens, sntID) => {
      if (sntID === sID) {
        console.log(`Updating tokens for sentence ${sntID} vs ${sID}`);
        return perSentTokens.map((tokenDict, tokenId) => 
          updateTokenBgc(tokenDict, tokenVar)
        );
      } else {
        console.log(`Copying tokens for sentence ${sntID} vs ${sID}`);
        return perSentTokens.map(tokenDict => ({ ...tokenDict }));
      }
    });
    setAlignedTokens(newAlignedTokens);

    // update amr
    const newStyledTokens = sntAMRtokens[sentID].map((tokenDict) => {
      if (tokenDict.value === tokenVar){
        return {...tokenDict, bgc: tokenDict.bgc === 'black' ? '#F3F3F3' : 'black'};
      } else {
        return {...tokenDict};
      }
    });
    const newSentAMRs = sntAMRtokens.map((sentAMRTokens, sntID) => {
      if (sntID === sID){
        return newStyledTokens;
      } else {
        return sentAMRTokens;
      }
    })
    setSentAMRS(newSentAMRs);



  };

  return (
    <pre>
    {!codeOnly &&  <code className="penman hljs language-penman" style={{ whiteSpace: 'pre-wrap' }} data-highlighted="yes">
            {sntAMRtokens[sentID].map((token, index) => {
              switch (token.type) {
                case 'punctuation':
                  return <span key={`${sentID}-${index}`} className="hljs-punctuation">{token.value}</span>;
                case 'variable':
                  return <span key={`${sentID}-${index}`} className="hljs-variable" onClick={(e) => handleTokenClick(token.value, sentID)} style={{ backgroundColor: token.bgc }}>{token.value}</span>;
                case 'symbol':
                  return <span key={`${sentID}-${index}`} className="hljs-symbol">{token.value}</span>;
                case 'string':
                  return <span key={`${sentID}-${index}`} className="hljs-string">{token.value}</span>;
                case 'comment':
                  return <span key={`${sentID}-${index}`} className="hljs-comment">{token.value}</span>;
                case 'literal':
                  return <span key={`${sentID}-${index}`} className="hljs-literal">{token.value}</span>;
                default:
                  return token.value;
              }
            })}
          </code>
    }
    {codeOnly &&  <code className="penman hljs language-penman" style={{ whiteSpace: 'pre-wrap' }} data-highlighted="yes">
            {sntAMRtokens[sentID].map((token, index) => {
              switch (token.type) {
                case 'punctuation':
                  return <span key={`${sentID}-${index}`} className="hljs-punctuation">{token.value}</span>;
                case 'variable':
                  return <span key={`${sentID}-${index}`} className="hljs-variable">{token.value}</span>;
                case 'symbol':
                  return <span key={`${sentID}-${index}`} className="hljs-symbol">{token.value}</span>;
                case 'string':
                  return <span key={`${sentID}-${index}`} className="hljs-string">{token.value}</span>;
                case 'comment':
                  return <span key={`${sentID}-${index}`} className="hljs-comment">{token.value}</span>;
                case 'literal':
                  return <span key={`${sentID}-${index}`} className="hljs-literal">{token.value}</span>;
                default:
                  return token.value;
              }
            })}
          </code>
    }
    </pre>
  );
};

export default PenmanHighlight;