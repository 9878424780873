import React, { useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Markdown from 'markdown-to-jsx';
import Box from '@mui/material/Box';

function InterpreterRender({ queryFunction, open, setOpen, anchorEl }) {
  const [loading, setLoading] = useState(false);
  // const [content, setContent] = useState('');

  const content = `
  Sorry feature under construction ... （。・＿・。）ﾉ
`
  // const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   queryFunction()
  //     .then((result) => {
  //       setContent(result);
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       setContent("Error loading content.");
  //       setLoading(false);
  //     });
  // }, [queryFunction]);

  const tooltipContent = loading ? (
    <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
      <CircularProgress size={24} />
      <Typography sx={{ ml: 2 }}>Loading...</Typography>
    </div>
  ) : (
    <div style={{ maxWidth: 500 }}>
      <Markdown>{content}</Markdown>
    </div>
  );

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{
            minWidth: 300, // Minimum width of the popover
            padding: 2,
          }}>
        {tooltipContent}
      </Box>
    </Popover>
  );
}

export default InterpreterRender