import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardActions, Container, Chip, Typography, IconButton, Fab, Stack, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
// for cluster title editing popover component
import Popover from '@mui/material/Popover';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
// end of cluster title editing popover component 
import InterpreterRender from './InterpreterRender';
import "./AMRMapper.css";

const DividerIcon = () => (
  <span style={{ height: '24px', borderLeft: '3px solid grey', marginLeft: '8px', marginRight: '8px' }}></span>
);
const fabStyle = {
  position: 'absolute',
  bottom: 70,
  right: 16,
};

const fab = {
      color: 'primary',
      sx: fabStyle,
      icon: <AddIcon />,
      label: 'Add',
};

const options = {
  shouldForwardProp: (prop) => prop !== 'hoverShadow',
};


const StyledCard = styled(Card, { name: 'StyledCard' })(({ theme, hoverShadow = 1, selected }) => ({
  ':hover': {
    boxShadow: theme.shadows[hoverShadow],
  },
  backgroundColor: selected ? theme.palette.primary.main : theme.palette.background.paper,
  color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
}));

const AMRMapper = ({ onCreateNewCluster, onDeleteCluter, allClusters, setAllClusters, uploadAMRCorefs, currentCluster, setCurrentCluster, useAIInterpreter }) => {  
  const [selectedChips, setSelectedChips] = useState([]);


  useEffect(() => {
    console.log('Selected chips updated:', selectedChips);
  }, [selectedChips]);

  // useEffect(() => {
  //   getMsDocPage().then(res => {
  //     console.log('MsDocPage:', res.data);
  //   });
  // }, []);

  const handleChipClick = (chipId) => {
    console.log(chipId, 'has been selected');
  };

  const handleClusterClick = (clusterID) => {
    console.log(clusterID, 'has been clicked');
    
    setCurrentCluster((prevIndex) => (prevIndex === clusterID ? null : clusterID));
  };

  const isSelected = (chipId) => selectedChips.includes(chipId);

  const handleButtonClick = (event, clusterID) => {
    event.stopPropagation();
    onDeleteCluter(clusterID);
  };

  //start of comment popover----------------
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentOpen, setCommentOpen] = useState(false);
  const [commentIdx, setCommentIdx] = useState(null);
  const [commentValue, setCommentValue] = useState('');

  const handleCommentClose = () => {
    setCommentOpen(false);
    setAnchorEl(null);
  };
  const handleCommentClick = (index, e) => {
    e.preventDefault();
    console.log('index: ', index);
    console.log('cluster.name: ', allClusters[index].name);
    // logic of the commenting here
    setCommentIdx(index);
    setAnchorEl(e.currentTarget);
    setCommentOpen(true);
  };

  const handleClickCommentSubmit = async (e) => {
    e.preventDefault();
    // update the row commnets
    console.log('allClusters before: ', allClusters);
    console.log('newComment: ', commentValue);
    const newCluster = {...allClusters[commentIdx], 'name':commentValue};
    console.log('newCluster: ', newCluster);
    const newClusters = {...allClusters, [commentIdx]: newCluster};

    setAllClusters(newClusters);
    console.log('newclusters : ', newClusters);
    // update the App state.
    uploadAMRCorefs(newClusters);
    // submit cluster name changes to the DB


    setCommentValue('');
   
    setCommentOpen(false);
  };

  const handleClickCommentDismiss = (e) => {
    e.preventDefault();
    setCommentIdx(null);
    setCommentValue('');
    setCommentOpen(false);
  };

  // start of interpreter tooltip----------
  const [interpreterOpen, setInterpreterOpen] = useState(false);
  const [anchorElInterp, setAnchorElInterp] = React.useState(null);
  const handleInterpreterPop = (e) => {
    e.stopPropagation();
    setAnchorElInterp(e.currentTarget);
    setInterpreterOpen(true);
  };
  // end of interpreter tooltip -----------

  //end of comment popover------------------
  return (    
    <Stack m={3} gap={1} sx={{margin: "5px 5px 5px 12px"}}>
      <Popover 
        id='popover-comment'
        open={commentOpen}
        anchorEl={anchorEl}
        onClose={handleCommentClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
            <InputLabel>note</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              endAdornment={
                <InputAdornment position="end">
                  <Box display="flex">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={(e) => handleClickCommentSubmit(e)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <TaskAltIcon />
                    </IconButton>
                    <IconButton
                      aria-label="edit password"
                      onClick={(e) => handleClickCommentDismiss(e)}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </InputAdornment>
              }
              value={commentValue}
              onChange={(e)=> {setCommentValue(e.target.value)}}
              label="note"
            />
          </FormControl>
        </Box>
      </Popover>
      <InterpreterRender open={interpreterOpen} setOpen={setInterpreterOpen} anchorEl={anchorElInterp}/>
      {Object.entries(allClusters).map(([clusterID, cluster]) => (
        <StyledCard 
          hoverShadow={10} 
          id={`cluster-{clusterID}`}
          key={clusterID}
          selected={currentCluster === clusterID}
          onClick={(e) => handleClusterClick(clusterID)}
          sx={{
            border: '1px solid darkgray'
          }}
        >
          <CardContent sx={{paddingBottom: '5px'}}>
            {cluster.nodes.map((nodeDict) => (
              <Chip 
                key={`${nodeDict.var}-${nodeDict.surface}`}
                className={"m-1 document-chip"} 
                onClick={() => handleChipClick(nodeDict.var)}
                sx={{
                  border: '1px solid #000000',
                  backgroundColor: 'white', // Non-transparent filled background color
                  color: 'black',                  // Text color
                }}
                label={<>{nodeDict.var}<DividerIcon/>{nodeDict.surface}</>}
              />
            ))}
          </CardContent>
          <CardActions sx={{paddingTop: "0px"}}>
            <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
              <Box>
              <Button 
                size="small" 
                id={cluster.id} 
                onClick={(e) => handleButtonClick(e, clusterID)} 
                color="error" 
                variant="contained" 
                sx={{
                  borderRadius: '5px',
                  padding: '4px 4px',
                  textTransform: 'none',
                  marginRight: '2px'

                }}
              >remove</Button>
              {useAIInterpreter && <Button 
                              size="small" 
                              id={cluster.id} 
                              onClick={(e) => handleInterpreterPop(e)} 
                              color="warning" 
                              variant="contained" 
                              sx={{
                                borderRadius: '5px',
                                padding: '4px 4px',
                                textTransform: 'none'
              
                              }}
                            >Interpret</Button>}
              </Box>
              <Typography variant="h6" color="text.secondary" onContextMenu={(e)=> handleCommentClick(clusterID, e)}
              >
                {cluster.name}
              </Typography>
            </Stack>
          </CardActions>
        </StyledCard>
      ))}
      <Fab 
        sx={fab.sx}
        aria-label={fab.label}
        color={fab.color}
        onClick={(e) => onCreateNewCluster()}
      >
        {fab.icon}
      </Fab>
    </Stack>
  );
};

export default AMRMapper;