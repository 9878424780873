import React, { useState, useRef, useEffect } from 'react';
import {
  Box, TextField, Button, Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ApiQueryForm({show, onHide, endpoint, apiKey, queryText, onConfirm, onCancel}) {
  const [localEndpoint, setLocalEndpoint] = useState(endpoint);
  const [localApiKey, setLocalApiKey] = useState(apiKey);
  const [localQueryText, setLocalQueryText] = useState(queryText);

  const handleCancel = () => {
    setLocalEndpoint(endpoint);
    setLocalApiKey(apiKey);
    setLocalQueryText(queryText);
    onCancel();
    onHide();
  };

  const handleConfirm = async (event) => {
    event.preventDefault();
    onConfirm(localEndpoint, localApiKey, localQueryText);
    onHide();
  };

  return (
    <Dialog 
      open={show}
      onClose={onHide}
      maxWidth='sm'
      fullWidth={true}
      style={{zIndex: 12}}
    >    
      <DialogTitle style={{ textAlign: 'center', margin: 'auto' }}>AI Interpreter</DialogTitle>
      <DialogContent style={{zIndex: 12}}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            API Query Form
          </Typography>

          <TextField
            required
            fullWidth
            label="API Endpoint URL"
            variant="outlined"
            value={localEndpoint}
            onChange={(e) => setLocalEndpoint(e.target.value)}
            sx={{ mb: 2 }}
          />

          <TextField
            required
            fullWidth
            type="password"
            label="API Key"
            variant="outlined"
            value={localApiKey}
            onChange={(e) => setLocalApiKey(e.target.value)}
            sx={{ mb: 2 }}
          />

          <TextField
            required
            fullWidth
            multiline
            minRows={4}
            label="Query Text"
            variant="outlined"
            value={localQueryText}
            onChange={(e) => setLocalQueryText(e.target.value)}
            sx={{ mb: 2 }}
          />

      </DialogContent>
      <DialogActions>
        {/*<Button variant="warning" onClick={handleFileSelection}>Open</Button>*/}
        <Button variant="outlined" onClick={handleCancel} color="secondary">Cancel</Button>
        <Button variant="outlined" onClick={handleConfirm} color="primary">Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ApiQueryForm;